.shopify-date-picker .react-calendar__navigation {
  @apply relative h-6 mr-1;
}

.shopify-date-picker .react-calendar__navigation__arrow {
  @apply absolute !min-w-[24px] max-w-[24px];
}
.shopify-date-picker .react-calendar__navigation__label {
  @apply absolute top-[2px];
}

.shopify-date-picker .react-calendar__navigation__prev-button {
  @apply right-[29px];
}

.shopify-date-picker .react-calendar__navigation__next-button {
  @apply right-0;
}

.shopify-date-picker .react-calendar__month-view__weekdays__weekday--weekend {
  @apply text-primaryPurple dark:!text-lilac400;
}

.shopify-date-picker .react-calendar__tile {
  @apply !border-0;
}

.shopify-date-picker .react-daterange-picker__wrapper {
  @apply flex justify-between mb-[26px];
}
.shopify-date-picker .react-daterange-picker__range-divider {
  @apply hidden;
}
.shopify-date-picker .react-daterange-picker__inputGroup {
  @apply hidden;
}

.shopify-date-picker .react-daterange-picker__button {
  @apply hidden;
}

.shopify-date-picker .react-calendar__tile:disabled {
  @apply !rounded-full bg-neutral1 text-grey300 !font-medium dark:bg-grey700 dark:text-grey300 cursor-[not-allowed] hover:bg-neutral1 hover:text-grey300 dark:hover:bg-grey700 dark:hover:text-grey300;
}
.shopify-date-picker .react-daterange-picker__calendar {
  @apply !static !h-fit;
}

.shopify-date-picker .react-daterange-picker__calendar {
  @apply bg-white dark:bg-black900 rounded-10 py-[15px];
  box-shadow: 0px 8px 16px -6px rgba(20, 23, 26, 0.04),
    0px 6px 8px -6px rgba(20, 23, 26, 0.06);
}

.shopify-date-picker .react-calendar__tile--rangeStart {
  @apply !rounded-l-full;
}
.shopify-date-picker .react-calendar__tile--rangeEnd {
  @apply !rounded-r-full;
}
.shopify-date-picker .react-calendar__tile--hoverStart {
  @apply !rounded-r-[0px];
}
.shopify-date-picker .react-calendar__tile--hoverEnd {
  @apply !rounded-l-[0px];
}

.shopify-date-picker .react-calendar__tile--rangeStart,
.shopify-date-picker .react-calendar__tile--rangeEnd,
.shopify-date-picker .react-calendar__tile--hoverEnd,
.shopify-date-picker .react-calendar__tile--hoverStart {
  @apply relative hover:!bg-lilac400  dark:hover:!bg-grey100;
}
.shopify-date-picker .react-calendar__tile--rangeStart > abbr,
.shopify-date-picker .react-calendar__tile--rangeEnd > abbr,
.shopify-date-picker .react-calendar__tile--hoverEnd > abbr,
.shopify-date-picker .react-calendar__tile--hoverStart > abbr {
  @apply bg-primaryPurple dark:bg-purple500 w-10 h-10 absolute left-0 top-0 rounded-full flex items-center justify-center dark:text-lilac400 text-white;
}
.shopify-date-picker .react-calendar__tile--now {
  @apply relative;
}
.shopify-date-picker .react-calendar__tile--now > abbr {
  @apply w-10 h-10 absolute left-0 top-0 rounded-full flex items-center justify-center;
}

.shopify-date-picker .react-calendar__tile--hover {
  @apply relative !bg-lilac400 rounded-[0px]  dark:!bg-grey100 dark:text-grey700;
}
.shopify-date-picker .react-calendar__tile {
  @apply hover:bg-primaryPurple dark:hover:bg-purple500 dark:hover:text-lilac400 hover:text-white;
}

.shopify-date-picker .react-calendar__tile--hoverBothEnds {
  @apply !rounded-full;
}

.shopify-date-picker .react-calendar__tile--rangeStart:disabled > abbr,
.shopify-date-picker .react-calendar__tile--rangeEnd:disabled > abbr,
.shopify-date-picker .react-calendar__tile--hoverEnd:disabled > abbr,
.shopify-date-picker .react-calendar__tile--hoverStart:disabled > abbr,
.shopify-date-picker .react-calendar__tile--hover:disabled {
  @apply !bg-neutral1 text-grey300 !font-medium dark:!bg-grey700 dark:text-grey300;
}
.sales-tracker-animation {
  @apply relative;
}
.sales-tracker-animation > abbr {
  @apply absolute rounded-full !bg-primaryPurple dark:!bg-purple500 dark:!text-lilac400 !text-white !w-[24px] !h-[24px] !top-[8px] !left-[8px] flex items-center justify-center animate-[calendar-pulse_3s_ease-in-out_infinite] dark:animate-[dark-calendar-pulse_3s_ease-in-out_infinite];
}

@keyframes calendar-pulse {
  0% {
    box-shadow: 0 0 0 3px rgba(256, 256, 256, 1);
  }
  25% {
    box-shadow: 0 0 0 3px rgba(200, 192, 242, 0.8);
  }
  50% {
    box-shadow: 0 0 0 3px rgba(200, 192, 242, 0.8),
      0 0 0 6px rgba(200, 192, 242, 0.5);
  }
  75% {
    box-shadow: 0 0 0 3px rgba(200, 192, 242, 0.8);
  }
  100% {
    box-shadow: 0 0 0 3px rgba(256, 256, 256, 1);
  }
}

@keyframes dark-calendar-pulse {
  0% {
    box-shadow: 0 0 0 3px rgba(37, 37, 37, 1);
  }
  25% {
    box-shadow: 0 0 0 3px rgba(200, 192, 242, 0.8);
  }
  50% {
    box-shadow: 0 0 0 3px rgba(200, 192, 242, 0.8),
      0 0 0 6px rgba(200, 192, 242, 0.5);
  }
  75% {
    box-shadow: 0 0 0 3px rgba(200, 192, 242, 0.8);
  }
  100% {
    box-shadow: 0 0 0 3px rgba(3, 7 37, 37, 1);
  }
}
