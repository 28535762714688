.table-comp-body tr:first-child .zoomed-image {
  @apply top-[-30px] -translate-y-[0px];
}

.table-comp-body tr:last-child .zoomed-image {
  @apply translate-y-[0px] -bottom-[30px] !top-[unset];
}
.table-comp-body tr:nth-last-child(2) .zoomed-image {
  @apply translate-y-[0px] -bottom-[50px] !top-[unset];
}
