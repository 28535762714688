.react-calendar {
  font-weight: 500;
  max-width: 280px;
  margin: 0 auto;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  @apply text-smoothGreen dark:text-paradiseBlue text-base mb-2 flex;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__month-view__weekdays__weekday--weekend {
  @apply text-smoothGreen dark:!text-paradiseBlue;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  font-weight: bold;
}
.react-calendar__month-view__weekdays__weekday {
  @apply text-xs p-2 dark:text-grey100;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.hide-text > abbr {
  display: none;
}

.selected-day {
  @apply !border-2 !border-solid !border-smoothGreen dark:!border-paradiseBlue dark:!border-paradiseBlue dark:!text-paradiseBlue;
}

.react-calendar__month-view__days__day--neighboringMonth {
  @apply text-secondary dark:text-grey500;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
  @apply !text-smoothGreen dark:!text-paradiseBlue;
}
.react-calendar__tile {
  font-size: 14px;
  width: 40px !important;
  height: 40px;
  border-radius: 100%;
  background: none;
  text-align: center;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  @apply border-2 border-smoothGreen dark:border-paradiseBlue border-solid rounded-full dark:bg-transparent dark:text-grey100;
}

.react-calendar__tile--hasActive {
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  @apply text-darkBlue bg-white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  @apply bg-primaryPurple dark:bg-purple500;
}

abbr {
  text-decoration: none !important;
  font-weight: 500;
}
