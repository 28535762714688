.line-1 {
  animation: line1 0.6s ease infinite;
}
.line-2 {
  animation: line2 0.6s ease infinite;
}
.line-3 {
  animation: line3 0.6s ease infinite;
}
.line-4 {
  animation: line4 0.6s ease infinite;
}
.line-5 {
  animation: line5 0.6s ease infinite;
}
.line-6 {
  animation: line6 0.6s ease infinite;
}
.line-7 {
  animation: line7 0.6s ease infinite;
}
.line-8 {
  animation: line8 0.6s ease infinite;
}

@keyframes line1 {
  0% {
    fill: #150d3f;
  }
  12.5% {
  }
  100% {
  }
}
@keyframes line2 {
  0% {
  }
  12.5% {
    fill: #150d3f;
  }
  25% {
  }
  100% {
  }
}

@keyframes line3 {
  0% {
  }
  25% {
    fill: #150d3f;
  }
  37.5% {
  }
  100% {
  }
}
@keyframes line4 {
  0% {
  }
  37.5% {
    fill: #150d3f;
  }
  50% {
  }
  100% {
  }
}
@keyframes line5 {
  0% {
  }
  50% {
    fill: #150d3f;
  }
  62.5% {
  }
  100% {
  }
}
@keyframes line6 {
  0% {
  }
  62.5% {
    fill: #150d3f;
  }
  75% {
  }
  100% {
  }
}

@keyframes line7 {
  0% {
  }
  75% {
    fill: #150d3f;
  }

  87.5% {
  }
  100% {
  }
}

@keyframes line8 {
  0% {
  }
  87.5% {
    fill: #150d3f;
  }
  100% {
  }
}
