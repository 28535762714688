input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  --moz-appearance: textfield;
}

.transparent-scrollbar {
  scrollbar-width: none;
  scrollbar-color: black transparent;
}
.transparent-scrollbar:-webkit-scrollbar {
  scroll-margin-top: 100px;
  width: 1px;
  height: 1px !important;
}
.transparent-scrollbar:-webkit-scrollbar-track {
  background: transparent !important;
}
.transparent-scrollbar:-webkit-scrollbar-thumb {
  @apply bg-neutral2 rounded-[6px] border-[1px] border-transparent;
}

.transparent-scrollbar::-webkit-scrollbar,
.transparent-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  width: 1px;
  height: 1px;
  border-radius: 8px;
  border: 0px solid transparent;
}

.transparent-scrollbar::-webkit-scrollbar-thumb {
  box-shadow: 0px 6px 8px -6px rgba(20, 23, 26, 0.06),
    0px 8px 16px -6px rgba(20, 23, 26, 0.04);
}

.scrollbar {
  scrollbar-width: 10px;
  scrollbar-color: #ecf5ff transparent;
}
.scrollbar:-webkit-scrollbar {
  width: 11px;
  height: 4px !important;
}
.scrollbar:-webkit-scrollbar-track {
  background: transparent !important;
}
.scrollbar:-webkit-scrollbar-thumb {
  background-color: #ecf5ff;
  border-radius: 6px;
  border: 3px solid transparent;
}

body.dark .scrollbar {
  scrollbar-width: 10px;
  scrollbar-color: #383838 transparent;
}

.scrollbar::-webkit-scrollbar,
.scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  width: 10px;
  height: 11px;
  border-radius: 8px;
  border: 1px solid transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  /* border-top: 66px solid transparent; */
  @apply shadow-[inset_0px_0px_10px_10px_#ecf5ff] dark:shadow-[inset_0px_0px_10px_10px_#383838];
}

.white-scrollbar {
  scrollbar-width: 10px;
  scrollbar-color: #ffffff transparent;
}
.white-scrollbar:-webkit-scrollbar {
  width: 11px;
  height: 4px !important;
}
.white-scrollbar:-webkit-scrollbar-track {
  background: transparent !important;
}
.white-scrollbar:-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 6px;
  border: 3px solid transparent;
}

.white-scrollbar::-webkit-scrollbar,
.white-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  width: 10px;
  height: 11px;
  border-radius: 8px;
  border: 1px solid transparent;
}

.white-scrollbar::-webkit-scrollbar-thumb {
  @apply shadow-[inset_10px_10px_10px_5px_#ffffff] dark:shadow-[inset_10px_10px_10px_5px_#383838];
}
