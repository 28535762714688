.stepper {
  border: 2px solid #06ffc3 !important;
  font-family: "Alexandria", sans-serif;
  z-index: 1;
}

.inactive-stepper {
  border: 2px solid #c8e1ff !important;
  font-family: "Alexandria", sans-serif;
  z-index: 1;
}
#RFS-Connector {
  border: 1.5px solid #ecf5ff;
}

#RFS-ConnectorContainer {
  top: "50%" !important;
  left: calc((-50% + 2em) - 16px) !important;
  right: calc((50% + 2em) - 16px) !important;
}

.stepper__step {
  border: 1px solid #2b7cff !important;
  cursor: default !important;
}
