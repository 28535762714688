.firstLine {
  animation: loadingFirst 5s ease infinite;
}
.secondLine {
  animation: loadingSecond 5s ease infinite;
}
.thirdLine {
  animation: loadingThird 5s ease infinite;
}

@keyframes loadingFirst {
  0% {
    left: 50%;
  }
  10% {
    left: 50%;
  }
  31% {
    left: 150px;
  }
  52% {
    left: 50%;
  }
  58% {
    left: 50%;
  }
  79% {
    left: -150px;
  }
  100% {
    left: 50%;
  }
}

@keyframes loadingSecond {
  0% {
    left: 50%;
  }
  10% {
    left: 50%;
  }
  31% {
    left: 50px;
  }
  52% {
    left: 50%;
  }
  58% {
    left: 50%;
  }
  79% {
    left: -50px;
  }
  100% {
    left: 50%;
  }
}

@keyframes loadingThird {
  0% {
    left: 50%;
  }
  10% {
    left: 50%;
  }
  31% {
    left: -60px;
  }
  52% {
    left: 50%;
  }
  58% {
    left: 50%;
  }
  79% {
    left: 60px;
  }
  100% {
    left: 50%;
  }
}
