@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply bg-[#ebf4fe] dark:bg-[#383838] text-darkBlue dark:text-grey100;
  }
  /* * {
    @apply dark:text-grey100;
  } */
}
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Alexandria", sans-serif;
}
html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes sidebarExpandTitle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* this is to fix autofill background color on inputs */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

@media screen and (max-width: 767px) {
  _::-webkit-full-page-media,
  _:future,
  :root .safari_only {
    padding-bottom: 65px;
  }
}
