.mtnFirstLine {
  animation: firstLine 2800ms ease infinite;
}

.mtnSecondLine {
  animation: secondLine 2800ms ease infinite;
}

.mtnThirdLine {
  animation: thirdLine 2800ms ease infinite;
}

@keyframes firstLine {
  0% {
    height: 8px;
    background: #e3e3e3;
  }
  10% {
    height: 8px;
    background: #e3e3e3;
  }
  20% {
    height: 8px;
    background: #e3e3e3;
  }
  30% {
    height: 8px;
    background: #e3e3e3;
  }
  40% {
    height: 8px;
    background: #e3e3e3;
  }
  50% {
    height: 8px;
    background: #e3e3e3;
  }
  60% {
    height: 48px;
    background: #150d3f;
  }
  70% {
    height: 48px;
    background: #150d3f;
  }
  80% {
    height: 48px;
    background: #150d3f;
  }
  90% {
    height: 48px;
    background: #150d3f;
  }
  100% {
    height: 8px;
    background: #e3e3e3;
  }
}

@keyframes secondLine {
  0% {
    height: 8px;
    background: #e3e3e3;
  }
  10% {
    height: 8px;
    background: #e3e3e3;
  }
  20% {
    height: 8px;
    background: #e3e3e3;
  }
  30% {
    height: 8px;
    background: #e3e3e3;
  }
  40% {
    height: 8px;
    background: #e3e3e3;
  }
  50% {
    height: 8px;
    background: #e3e3e3;
  }
  60% {
    height: 8px;
    background: #e3e3e3;
  }
  70% {
    height: 48px;
    background: #1bcfba;
  }
  80% {
    height: 48px;
    background: #1bcfba;
  }
  90% {
    height: 48px;
    background: #1bcfba;
  }
  100% {
    height: 8px;
    background: #e3e3e3;
  }
}

@keyframes thirdLine {
  0% {
    height: 8px;
    background: #e3e3e3;
  }
  10% {
    height: 8px;
    background: #e3e3e3;
  }
  20% {
    height: 8px;
    background: #e3e3e3;
  }
  30% {
    height: 8px;
    background: #e3e3e3;
  }
  40% {
    height: 8px;
    background: #e3e3e3;
  }
  50% {
    height: 8px;
    background: #e3e3e3;
  }
  60% {
    height: 8px;
    background: #e3e3e3;
  }
  70% {
    height: 8px;
    background: #e3e3e3;
  }
  80% {
    height: 48px;
    background: #6831ff;
  }
  90% {
    height: 48px;
    background: #6831ff;
  }
  100% {
    height: 8px;
    background: #e3e3e3;
  }
}
